/* eslint-disable complexity */
import { useMemo } from 'react';
import { MdOpenInNew } from 'react-icons/md';

import { Column } from '@/components/Column';
import { Container } from '@/components/Container';
import { EmphasisText } from '@/components/EmphasisText';
import { Error } from '@/components/Error';
import { ErrorWrapper } from '@/components/ErrorWrapper';
import { Events } from '@/components/Events';
import { HeadingText } from '@/components/HeadingText';
import { Page } from '@/components/Page';
import { Spinner } from '@/components/Spinner';
import { Stat } from '@/components/Stat';
import { Stats } from '@/components/Stats';
import { Text } from '@/components/Text';
import { useTransportQuery } from '@/hooks/queries/useTransportQuery';
import { useTrackingNumber } from '@/hooks/useTrackingNumber';
import { formatDatePretty } from '@/utils/date';
import { formatAddress } from '@/utils/formatAddress';
import { getPortalUrl } from '@/utils/urls';

const PORTAL_URL = getPortalUrl();

const PortalLink = ({ trackingNumber }: { trackingNumber: string }) => {
  return (
    <a
      href={`${PORTAL_URL}/app/transports/${trackingNumber}`}
      rel='noopener noreferrer'
      target='_blank'
    >
      <div className='flex items-center gap-1 underline'>
        <Text>View on Intertrace Portal</Text>
        <MdOpenInNew className='text-lg' />
      </div>
    </a>
  );
};

const Track = () => {
  const trackingNumber = useTrackingNumber();
  const { data, error, isError, isLoading } = useTransportQuery({
    trackingNumber,
  });

  const errorMessage = useMemo(() => {
    if (data?.transport?.events?.length === 0) {
      return 'There are no events for this package.';
    }
  }, [data?.transport?.events?.length]);

  return (
    <Page>
      <Container>
        <Column className='gap-4 py-10 md:items-start'>
          <div>
            <HeadingText>
              Track the delivery of order
              <span className='ml-2 text-2xl font-extrabold underline'>
                #{trackingNumber}
              </span>
            </HeadingText>
          </div>
          {isLoading ? (
            <Spinner className='h-12 w-12' />
          ) : (
            <ErrorWrapper
              ErrorComponent={
                <Error
                  text={error?.response?.data?.error?.message ?? errorMessage}
                />
              }
              SuccessComponent={
                <>
                  <PortalLink trackingNumber={trackingNumber} />
                  <div className='flex w-full flex-col  xl:flex-row xl:space-x-5'>
                    {(data?.transport.pickupDate ||
                      data?.transport.deliveryDate ||
                      data?.transport.reference ||
                      data?.transport.senderCity ||
                      data?.transport.senderZip ||
                      data?.transport.senderCountry ||
                      data?.transport.recipientCity ||
                      data?.transport.recipientZip ||
                      data?.transport.recipientCountry ||
                      data?.transport.contentsCount) && (
                      <div className='mb-4 '>
                        <Stats>
                          {data?.transport?.courierTeam?.name && (
                            <Stat
                              title='Courier'
                              value={data.transport.courierTeam.name}
                            />
                          )}
                          {data?.transport.pickupDate && (
                            <Stat
                              title='Pickup date'
                              value={formatDatePretty(
                                new Date(data.transport.pickupDate)
                              )}
                            />
                          )}
                          {data?.transport.deliveryDate && (
                            <Stat
                              title='Delivery date'
                              value={formatDatePretty(
                                new Date(data.transport.deliveryDate)
                              )}
                            />
                          )}
                          {data?.transport.reference && (
                            <Stat
                              title='Reference'
                              value={data.transport.reference}
                            />
                          )}
                          {(data?.transport.senderCity ||
                            data?.transport.senderZip ||
                            data?.transport.senderCountry) && (
                            <Stat
                              title='Sender address'
                              value={formatAddress(
                                data.transport.senderCity,
                                data.transport.senderZip
                              )}
                            />
                          )}
                          {(data?.transport.recipientCity ||
                            data?.transport.recipientZip ||
                            data?.transport.recipientCountry) && (
                            <Stat
                              title='Recipient address'
                              value={formatAddress(
                                data.transport.recipientCity,
                                data.transport.recipientZip
                              )}
                            />
                          )}
                          {data?.transport?.contentsCount && (
                            <Stat
                              title='Contents Count'
                              value={data.transport.contentsCount.toString()}
                            />
                          )}
                        </Stats>
                      </div>
                    )}

                    <Events events={data?.transport.events ?? []} />
                  </div>
                </>
              }
              isError={isError || Boolean(errorMessage)}
            />
          )}
        </Column>
      </Container>
    </Page>
  );
};

export { Track };
