import { Link } from 'react-router-dom';

import { Container } from './Container';
import { Row } from './Row';

const LeftLink = () => (
  <Link to='/'>
    <img
      alt='Intertrace logo'
      className='max-h-20 px-4'
      src='/images/intertrace-logo-full.png'
    />
  </Link>
);

const Header = () => {
  return (
    <Row className='sticky left-0 top-0 z-10 bg-banner py-8 shadow-md'>
      <Container className='w-full'>
        <Row className='justify-between' isCentered>
          <LeftLink />
          {/* <Button className='px-2'>
            <MdLanguage />
          </Button> */}
        </Row>
      </Container>
    </Row>
  );
};

export { Header };
