import { type ReactNode } from 'react';
import { MdCalendarToday, MdLocationOn, MdOutbound } from 'react-icons/md';
import { useEvent } from '@/hooks/useEvent';
import { type TTransportEvent } from '@/types/models';
import { eventIcons } from '@/constants/eventIcons';
import { Image } from './Image';
import { Row } from './Row';

type EventCardValueProps = {
  icon: ReactNode;
  label: string;
};

const EventCardValue = ({ icon, label }: EventCardValueProps) => {
  return (
    <div className='flex items-center  space-x-1 text-gray-500 '>
      {icon}
      <p className='text-sm font-normal'>{label}</p>
    </div>
  );
};

type EventCardProps = {
  transportEvent: TTransportEvent;
};

const EventCard = ({ transportEvent }: EventCardProps) => {
  const { dateTime, location, name, uuid } = useEvent({ transportEvent });

  const iconName = eventIcons[uuid];

  return (
    <li className='mb-10 ms-6'>
      <span className='absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-gray-100 '>
        <MdCalendarToday />
      </span>
      <h4 className='mb-0.5 text-base font-semibold text-gray-900 '>
        {dateTime}
      </h4>

      <div className='flex items-center space-x-1 text-sm font-normal text-gray-500'>
        {iconName && (
          <Row className='h-4 w-4' isCentered>
            <Image src={`/icons/${iconName}.svg`} alt={`${name} icon`} />
          </Row>
        )}
        <label>{name}</label>
      </div>
      {location && (
        <EventCardValue
          icon={<MdLocationOn className='h-4 w-4' />}
          label={location}
        />
      )}
    </li>
  );
};

export { EventCard };
export type { EventCardProps };
