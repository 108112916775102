import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Column } from '@/components/Column';
import { Container } from '@/components/Container';
import { HeadingText } from '@/components/HeadingText';
import { Page } from '@/components/Page';
import { Row } from '@/components/Row';
import { Text } from '@/components/Text';

const SinkPageLink = () => {
  return (
    <a href='/sink' rel='noopener noreferrer' target='_blank'>
      <div className='flex items-center gap-1 underline'>
        <Text>Open sink page (only visible in development)</Text>
        <MdOpenInNew className='text-lg' />
      </div>
    </a>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { handleSubmit, register, watch } = useForm<{
    trackingNumber: string;
  }>();

  useEffect(() => {
    const sub = watch(({ trackingNumber }) => {
      if (trackingNumber && trackingNumber.length > 0) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    });

    return () => sub.unsubscribe();
  }, [watch]);

  const onSubmit = handleSubmit(({ trackingNumber }) => {
    const trimmedTrackingNumber = trackingNumber.trim();

    navigate(`/track/${trimmedTrackingNumber}`);
  });

  return (
    <Page className='flex flex-col justify-center'>
      <Container className='w-full max-w-2xl'>
        <form className='flex' onSubmit={onSubmit}>
          <Column className='flex-1 gap-1'>
            <label htmlFor='trackingNumber'>
              <HeadingText>Tracking number</HeadingText>
            </label>
            <Row className='flex-wrap items-center gap-2'>
              <input
                className='h-14 grow basis-2/3 rounded-md border-2 border-black p-2 text-lg focus:outline-accent'
                id='trackingNumber'
                placeholder='Enter tracking number...'
                type='text'
                {...register('trackingNumber', { required: true })}
              />
              <Button
                className='h-14 grow'
                disabled={isButtonDisabled}
                type='submit'
              >
                <strong>Track</strong>
              </Button>
            </Row>
          </Column>
        </form>
        {import.meta.env.DEV && <SinkPageLink />}
      </Container>
    </Page>
  );
};

export { Home };
